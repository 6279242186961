import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

/**
 * ニュース一覧でのナビゲーション
 */
export const NewsNavi = () => {
  const { t } = useTranslation()

  return (
    <nav className="side_nav">
      <div className="sticky">
        <h2>{t("ニュース一覧", "ニュース一覧")}</h2>
        <ul>
          <li>
            <Link to="/news">{t("すべてのお知らせ", "すべてのお知らせ")}</Link>
          </li>
          <li>
            <Link to="/news/important">
              {t("重要なお知らせ", "重要なお知らせ")}
            </Link>
          </li>
          <li>
            <Link to="/news/announcement">{t("お知らせ", "お知らせ")}</Link>
          </li>
          <li>
            <Link to="/news/media">{t("メディア情報", "メディア情報")}</Link>
          </li>
          <li>
            <Link to="/news/closed">
              {t("休診のお知らせ", "休診のお知らせ")}
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}
