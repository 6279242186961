/**
 * Contentful の Article モデルの news type
 * 対応するラベルと css class を返す
 * important と announcement は同じ css class
 */
export const newsTypeMap = {
  announcement: {
    label: "お知らせ",
    classes: "news",
  },
  media: {
    label: "メディア情報",
    classes: "media",
  },
  closed: {
    label: "休診",
    classes: "course",
  },
  important: {
    label: "重要",
    classes: "important",
  },
}

export const REGEXP_EMAIL_ADDRESS =
  /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
