import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import { newsTypeMap } from "utils"

/**
 * type ごとの news を表示する
 */
export const NewsByTypeTemplate = ({ data }) => {
  const { locale } = useLocalization()
  const { t } = useTranslation()

  const localizedNews = data.filter(
    ({ node }) => node.node_locale.slice(0, 2) === locale,
  )

  return localizedNews.map(({ node }) => {
    const { classes, label } = newsTypeMap[node.newsMeta.type]

    return (
      <div className="view_timer" key={node.contentful_id}>
        <dl className="inviewfadeInUp">
          <dt>
            {node.newsMeta.post_date}
            <span className={classes}>{t(label, label)}</span>
          </dt>
          <dd>
            <Link to={`/news/article/${node.contentful_id}`}>
              <div>{node.title}</div>
            </Link>
          </dd>
        </dl>
      </div>
    )
  })
}
